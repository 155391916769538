<template>
  <!-- 添加备注 -->
  <ShipDialog
      :title="title"
      :visible.sync="dialogTableVisible"
      :showTab="showTab"
      width="500px"
      size="small"
      center
      append-to-body
      :show-close="false"
      imgType="note"
  >
    <div class="ticket_div">
      <el-select
          size="small"
          placeholder="选择快捷备注"
          class="el_select"
          :value="selectTag"
          @blur="blur"
          v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
          :filter-method="filterRemark"
          filterable
          remote
          :remote-method="getRemarkList"
          @click.native="getRemarkList"
          clearable
      >
        <el-option
            v-for="item in remarkTagOptions.slice(0,rangeNumber)"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          <div class="remark_tag_div">
          <span class="remark_tag_label"
                @click="addRemark2Text(item.label)">{{ item.label }}</span>
            <span class="el-icon-error right_tag" @click="deleteUserRemarkTag(item.id)"></span>
          </div>
        </el-option>

      </el-select>
      <el-input
          type="textarea"
          maxlength="40"
          show-word-limit
          placeholder="请输入内容"
          v-model="textarea"
      ></el-input>
    </div>
    <DialogFooter
        :showTab="showTab"
        @confirm="addRemark"
        @cancel="closeDialog"
        :loading="confirmLoading"
    ></DialogFooter>
  </ShipDialog>
</template>
<script>
import {updateRemarkAndTruck, remarkShVessels} from "@/api/boxQuery";
import {shipRequest} from "@/views/ShipSubscrib/ship.js";
import {updateTruckSubRemark} from "@/api/truckSubscribe";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import {deleteUserRemarkTags, getUserRemarkTags} from "@/api/shanghaiManifest";

export default {
  props: ["showTab"],
  data() {
    return {
      test: "测试信息",
      title: "",
      textarea: "",
      dialogTableVisible: false,
      id: "",
      resultid: "",
      blno: "",
      oldremark: "",
      confirmLoading: false,
      remarkType: "ship",
      selectTag: "",
      rangeNumber: 10,
      remarkTagList: [],
      remarkTagOptions: [],
    };
  },
  components: {
    ShipDialog,
    DialogFooter,
  },
  mounted() {
  },
  methods: {
    // 船期/物流备注
    init({id, blno, vesselename, voyage, remark}) {
      this.selectTag = '';
      this.dialogTableVisible = true;
      if (remark) {
        this.title = "修改备注";
      } else {
        this.title = "添加备注";
      }
      this.textarea = remark;
      this.oldremark = remark;
      this.id = id;
      this.blno = blno;
      this.vesselename = vesselename;
      this.voyage = voyage;
      this.remarkType = "ship";
    },
    addRemark() {
      // if (this.textarea) {
      if (this.oldremark == this.textarea) {
        this.$message.warning("备注无改动，不需要保存");
        return;
      }
      const that = this;
      this.confirmLoading = true;
      if (this.remarkType === "ship") {
        // 宁波船期改备注
        const param = {
          remark: this.textarea,
          id: this.id,
        };

        // 上海船期改备注
        const paramSH = new FormData();
        paramSH.append("remark", this.textarea);
        paramSH.append("id", this.id);

        const reqFunc = shipRequest({
          tab: this.showTab,
          type: 7,
        });
        reqFunc(this.showTab === "NB" ? param : paramSH)
            .then(() => {
              that.$message.success("修改成功");
              that.$parent.remark = that.textarea;
              that.$emit("getData");
              that.$emit("refreshDataList", this.textarea);
              that.closeDialog();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
      } else if (this.remarkType === "car") {
        updateTruckSubRemark({
          id: this.id,
          remark: this.textarea,
        })
            .then(() => {
              this.$message.success("备注更新成功！");
              this.$emit("confirm");
              this.closeDialog();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
      }
    },
    closeDialog() {
      this.dialogTableVisible = false;
    },
    getRemarkList(val) {
      const data = {keywords: val && val.length > 1 ? val.trim() : ''}
      getUserRemarkTags(data).then((res) => {
        this.remarkTagList = res.data.data.map(x => {
          return {
            id: x.id,
            label: x.remark,
            value: x.remark,
          }
        });
        this.remarkTagOptions = this.remarkTagList;
      })
    },
    addRemark2Text(newVal) {
      this.textarea = this.textarea ? this.textarea + newVal : newVal;
    },
    //过滤备注tag数据
    filterRemark(query) {
      if (query) {
        this.remarkTagOptions = this.remarkTagList.filter(x => {
          return x.label.includes(query.toUpperCase())
        })
      } else {
        this.remarkTagOptions = this.remarkTagList
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    //删除用户备注标签
    deleteUserRemarkTag(id) {
      deleteUserRemarkTags({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.data);
        }
      }).finally(() => {
        this.getRemarkList();
      })
    }
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.el_select {
  margin-bottom: 1rem;
}

.el-image {
  width: 30rem;
  height: 30rem;
}

.right_tag {
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.remark_tag_label {
  width: 100%;
}

.remark_tag_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
